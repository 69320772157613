/*

NOTE: tag is not supported in horizontal menu

Array of object

Top level object can be:
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- nav Grp (top level grp is called header group) ---/

title
icon (if it's on top/second level)
children

/--- nav Item (top level item is called header link) ---/

icon (if it's on top/second level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)

*/
import logout from '@/navigation/horizontal/logout'
import quiz from '@/navigation/horizontal/quiz'
import rule from '@/navigation/horizontal/rule'
import staff from '@/navigation/horizontal/staff'
import file from '@/navigation/horizontal/file'
// import pressroom from '@/navigation/horizontal/pressroom'
// import training from '@/navigation/horizontal/training'
import project from '@/navigation/horizontal/project'
import dashboard from './dashboard'

// Array of sections
export default [...dashboard, ...project, ...quiz, ...rule, ...file, ...staff, ...logout]
